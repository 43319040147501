import './App.css';
import '../common/utils.css';

import { Icon, IconButton, PacmanLoader } from '@coop/components';
import { ArrowLeftIcon, CloseIcon } from '@coop/icons';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import type { AuthenticationStateResult } from '../common/api/api.types';
import { getCoopseLoginUrl } from '../common/utils';
import AccessDenied from '../components/AccessDenied/AccessDenied';
import PunchoutSignin from '../components/Punchout/components/PunchoutSignin';
import AppContext, { type IAppContext } from '../context/appContext';
import { isLoginStateBankidOnly } from '../core/authState.utils';
import { useAuthState } from '../hooks/useAuthState';
import { SpaRoutes } from '../hooks/useLoginNavigate';
import dispatchEvent from '../lib/dispatchEvent';
import ChangeEmail from './andra-email/ChangeEmail';
import ChangePassword from './andra-losenord/ChangePassword';
import ResetPassword from './aterstall-losenord/ResetPassword';
import Confirm from './bekrafta/Confirm';
import ErrorPage from './error/ErrorPage';
import ForgotPassword from './glomt-losenord/ForgotPassword';
import AccountRemoved from './kontot-borttaget/AccountRemoved';
import Login from './logga-in/Login';
import LoginOmbudshandel from './logga-in/LoginOmbudshandel';
import Logout from './logga-ut/Logout';
import LoggedIn from './LoggedIn';
import RemoveAccount from './radera-kundkonto/RemoveAccount';
import CreateNewAccount from './skapa-kundkonto/CreateNewAccount';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    },
});

const App = () => {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <LoadApp />
            </QueryClientProvider>
        </BrowserRouter>
    );
};

const LoadApp = () => {
    const [backUrl, setBackUrl] = useState('');
    const [showNavigation, setShowNavigation] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showBackIcon, setShowBackIcon] = useState(false);
    const { data: authState, isError, isSuccess, refetch } = useAuthState();
    const location = useLocation();
    const bankIdOnly = isSuccess && isLoginStateBankidOnly(authState);
    const isOmbudshandel = !!authState?.loginRequest?.clientId?.startsWith('kommunal-admin');

    const context = useMemo((): IAppContext | undefined => {
        if (!authState) {
            return undefined;
        }

        return {
            backUrl,
            setBackUrl,
            showNavigation,
            setShowNavigation,
            showBackIcon,
            setShowBackIcon,
            bankIdOnly,
            authState,
            refetchAuthState: async () => {
                await refetch();
            },
        };
    }, [
        backUrl,
        setBackUrl,
        showNavigation,
        setShowNavigation,
        showBackIcon,
        setShowBackIcon,
        bankIdOnly,
        authState,
        refetch,
    ]);

    if (isError) {
        return (
            <div className="App App--noNav">
                <div className="App-content">
                    <ErrorPage />
                </div>
            </div>
        );
    }

    if (!context) {
        return (
            <div className="App App--noNav">
                <div className="App-content u-flex u-flexCenter">
                    <PacmanLoader />
                </div>
            </div>
        );
    }
    return (
        <AppContext.Provider value={context}>
            <Routes>
                <Route
                    path="/punchout/signin"
                    element={<PunchoutSignin authState={context.authState} />}
                />
                <Route
                    path="*"
                    element={
                        <div className={`App  ${showNavigation ? '' : 'App--noNav'}`}>
                            {context.authState && (
                                <AppHeader
                                    scrollPosition={scrollPosition}
                                    authState={context.authState}
                                />
                            )}
                            <div
                                className="App-content"
                                onScroll={(event) =>
                                    setScrollPosition((event.target as HTMLElement).scrollTop)
                                }
                            >
                                <Routes>
                                    <Route path="/andra-losenord/*" element={<ChangePassword />} />
                                    <Route path="/andra-email/*" element={<ChangeEmail />} />
                                    <Route path="/kontot-borttaget" element={<AccountRemoved />} />
                                    <Route path="/aterstall-losenord" element={<ResetPassword />} />
                                    <Route path="/bekrafta/*" element={<Confirm />} />
                                    <Route
                                        path="/bekrafta-bankid"
                                        element={
                                            <Navigate
                                                to={{
                                                    pathname: SpaRoutes.Confirm.Bankid,
                                                    search: location.search,
                                                }}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/bekrafta-losenord"
                                        element={
                                            <Navigate
                                                to={{
                                                    pathname: SpaRoutes.Confirm.Password,
                                                    search: location.search,
                                                }}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/Account/AccessDenied"
                                        element={<AccessDenied />}
                                    />
                                    <Route path="/glomt-losenord" element={<ForgotPassword />} />
                                    <Route path="/error" element={<ErrorPage />} />
                                    <Route
                                        path="/logga-in/*"
                                        element={isOmbudshandel ? <LoginOmbudshandel /> : <Login />}
                                    />
                                    <Route
                                        path="/logga-ut"
                                        element={<Logout authState={context.authState} />}
                                    />
                                    <Route path="/radera-kundkonto/*" element={<RemoveAccount />} />
                                    <Route
                                        path="/skapa-kundkonto/*"
                                        element={<CreateNewAccount />}
                                    />
                                    <Route path="/" element={<LoggedIn />} />
                                    <Route
                                        path="/*"
                                        element={
                                            <Navigate to={{ pathname: '/logga-in' }} replace />
                                        }
                                    />
                                </Routes>
                            </div>
                        </div>
                    }
                />
            </Routes>
        </AppContext.Provider>
    );
};

interface AppHeaderProps {
    scrollPosition: number;
    authState: AuthenticationStateResult;
}

const AppHeader = (props: AppHeaderProps) => {
    const location = useLocation();

    const context = React.useContext(AppContext);
    const cancelAppUrl = props.authState.loginRequest?.cancelAppUrl;
    const navigate = useNavigate();

    const handleBack = () => {
        if (context?.backUrl) {
            navigate(context.backUrl);
        } else {
            navigate(-1);
        }
    };

    const handleBankIdBack = () => {
        context?.setShowBackIcon(!context.showBackIcon);
    };

    const handleClose = () => {
        dispatchEvent(`app-window-close-${validMobileAppEvents(location.pathname)}`);
        window.location.href = getCoopseLoginUrl();
    };

    // Reset some context states on location change, no need to do it in every view.
    React.useEffect(() => {
        context?.setBackUrl('');
    }, [location, context]);

    return (
        <header
            className={`App-header ${props.scrollPosition > 0 ? 'App-header--withBoxShadow' : ''}`}
        >
            {context?.showNavigation && (
                <nav className="App-nav">
                    {context.bankIdOnly && !!cancelAppUrl ? (
                        <>
                            {context?.showBackIcon && (
                                <IconButton
                                    icon={ArrowLeftIcon}
                                    className="App-navBack"
                                    onClick={handleBankIdBack}
                                    theme="primaryOutline"
                                    label="Tillbaka"
                                />
                            )}
                            <a href={cancelAppUrl || '/'} className="Button App-navClose">
                                <Icon icon={CloseIcon} title="Stäng" />
                            </a>
                        </>
                    ) : (
                        <>
                            {location.pathname !== '/' && context?.backUrl && (
                                <IconButton
                                    icon={ArrowLeftIcon}
                                    className="App-navBack"
                                    onClick={handleBack}
                                    theme="primaryOutline"
                                    label="Gå tillbaka till föregående sida."
                                />
                            )}
                            <IconButton
                                icon={CloseIcon}
                                className="App-navClose"
                                onClick={handleClose}
                                theme="primaryOutline"
                                label="Stäng fönstret"
                            />
                        </>
                    )}
                </nav>
            )}
        </header>
    );
};

/** The mobile app is just listening to some special close events (should be change to all that starts with app-window-close-). */
const validMobileAppEvents = (location: string) => {
    if (!location) {
        return 'logga-in';
    }
    if (location.startsWith('/andra-email')) {
        return 'andra-email';
    }

    return location.replace('/', '');
};

export default App;
