'use client';

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import classnames from 'classnames';
import type { FC } from 'react';
import * as React from 'react';

import { Loader } from '../Loader';
import { useDelayedIsLoading } from '../Loader/Loader.hooks';
import { PacmanLoader } from '../PacmanLoader';
import styles from './OverlayLoader.module.scss';

export interface OverlayLoaderProps {
    additionalClasses?: string;
    type?: 'balls' | 'pacman';
    isLoading?: boolean;
    delay?: number;
    size?: Parameters<typeof Loader>[0]['size'];
    options?: {
        height?: string;
        noOpacity?: boolean;

        /**
         * Loader with an absolute positioning
         */
        isOverlaySection?: boolean;
    };
}

export const OverlayLoader: FC<React.PropsWithChildren<OverlayLoaderProps>> = (props) => {
    const classes = classnames(
        props.options && props.options.isOverlaySection && styles.LoaderOverlay,
        props.options && props.options.isOverlaySection && styles.LoaderOverlaySection,
        props.options && props.options.noOpacity && styles.LoaderNoOpacity,
        props.additionalClasses,
    );

    const enableDelay = !!props.delay;
    const showDelayedLoader = useDelayedIsLoading(props.isLoading, props.delay);

    if (props.isLoading === false) {
        return null;
    }

    if (enableDelay && !showDelayedLoader) {
        return null;
    }

    const isLoading = showDelayedLoader || props.isLoading;

    const css: React.CSSProperties = {
        opacity: 1,
        height: (props.options && props.options.height) || '100%',
        visibility: 'visible',
    };

    if (props.type === 'pacman') {
        return (
            <div className={classes} style={css}>
                <PacmanLoader isLoading={isLoading} />
            </div>
        );
    }

    return (
        <div className={classes} style={css}>
            <Loader isLoading={isLoading} size={props.size} />
        </div>
    );
};

export const OverlayPacmanLoader = (props: Omit<OverlayLoaderProps, 'type'>) => {
    return <OverlayLoader {...props} type="pacman" options={{ isOverlaySection: true }} />;
};

export const FullOverlayLoaderWrapper = ({
    isLoading,
    children,
}: {
    isLoading: boolean;
    children: React.ReactNode;
}) => {
    return isLoading ? (
        <OverlayLoader options={{ isOverlaySection: true, noOpacity: true }} />
    ) : (
        <>{children}</>
    );
};
