'use client';

import classNames from 'classnames';
import * as React from 'react';

import { useDelayedIsLoading } from '../Loader/Loader.hooks';
import styles from './PacmanLoader.module.scss';

interface PacmanLoaderProps {
    delay?: number;

    /** By default, loader is shown until unmounted, but you can control it with this prop */
    isLoading?: boolean;
}

const PacmanLoader = (props: PacmanLoaderProps) => {
    const enableDelay = !!props.delay;
    const showDelayedLoader = useDelayedIsLoading(props.isLoading, props.delay);

    if (props.isLoading === false) {
        return null;
    }

    if (enableDelay && !showDelayedLoader) {
        return null;
    }

    return (
        <div className={classNames(styles.Pacman)}>
            <div className={classNames(styles['Pacman-icon'])}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export default PacmanLoader;
