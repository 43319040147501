/* eslint-disable react/jsx-props-no-spreading */
import '../../../../../../components/Form/Form.css';

import { Button, PacmanLoader } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { accountApi } from '../../../../../../common/api/api.accounts';
import type { ApiException } from '../../../../../../common/api/api.types';
import { regex } from '../../../../../../common/utils';
import TextInputRHF from '../../../../../../components/TextInputRHF/TextInputRHF';
import useLoginNavigate, { SpaRoutes } from '../../../../../../hooks/useLoginNavigate';

interface FormData {
    verificationCode: string;
}
const VerifyNewEmail = () => {
    const [disabledSendAgainButton, setDisabledSendAgainButton] = useState(true);
    const { email } = useParams();
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        let handle: NodeJS.Timeout;
        if (disabledSendAgainButton) {
            handle = setTimeout(() => setDisabledSendAgainButton(false), 10000);
        }
        return () => clearTimeout(handle);
    }, [disabledSendAgainButton]);

    const { control, handleSubmit, formState } = useForm<FormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const navigate = useLoginNavigate();
    if (!email) {
        navigate(SpaRoutes.CreateAccount.Private.BankId.RegisterEmail);
    }
    const createEmailAccountMutation = useMutation<
        void,
        ApiException,
        { email: string; code: string }
    >({
        mutationFn: async (params) => {
            if (executeRecaptcha) {
                const recaptcha = await executeRecaptcha();
                await accountApi.createNewAccountWithBankid(params.email, params.code, recaptcha);
            }
        },
        onSuccess: () => {
            navigate(SpaRoutes.Login.RememberMe);
        },
    });

    const sendVerificationMailMutation = useMutation<void, ApiException, string>({
        mutationFn: accountApi.createAccountSendEmailVerificationCode,
    });

    const sendEmailVerificationClick = () => {
        if (email) {
            sendVerificationMailMutation.mutateAsync(email);
        }
    };

    const handleStartFormSubmit = (formData: FormData) => {
        if (formState.isValid && email) {
            createEmailAccountMutation.mutate({ email, code: formData.verificationCode });
        }
    };
    const isLoading =
        createEmailAccountMutation.isPending || sendVerificationMailMutation.isPending;

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Ange verifikationskod</h1>
            <p className="u-textCenter">
                Ange verifikationskoden du fick i mailet skickat till <strong>{email}</strong>
            </p>
            <form className="Form" onSubmit={handleSubmit(handleStartFormSubmit)}>
                <Controller
                    render={({ field, fieldState }) => (
                        <TextInputRHF
                            containerClass="u-marginTmd"
                            label="Verifikationskod"
                            type="number"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                        />
                    )}
                    defaultValue=""
                    name="verificationCode"
                    rules={{
                        required: 'Ange verifikationskod',
                        pattern: {
                            value: regex.verificationCode,
                            message: 'Ange verifikationskod',
                        },
                    }}
                    control={control}
                />
                <footer className="u-marginTauto">
                    {sendVerificationMailMutation.isError && (
                        <div className="u-marginTmd">
                            Din session har utgått, prova börja om från början
                        </div>
                    )}
                    {isLoading && (
                        <div className="u-flex u-flexCenter u-marginT">
                            <PacmanLoader />
                        </div>
                    )}
                    {createEmailAccountMutation.isError && (
                        <p className="Notice Notice--red">
                            {createEmailAccountMutation.error.friendlyMessage}
                        </p>
                    )}
                    <Button
                        theme="primary"
                        size={48}
                        fullWidth
                        className="u-marginT"
                        type="submit"
                        disabled={!formState.isValid || isLoading || !executeRecaptcha}
                    >
                        Verifiera
                    </Button>
                    <Button
                        theme="primaryOutline"
                        size={48}
                        fullWidth
                        className="u-marginTmd"
                        type="button"
                        disabled={disabledSendAgainButton || isLoading || !executeRecaptcha}
                        onClick={sendEmailVerificationClick}
                    >
                        Skicka verifikationskoden igen
                    </Button>
                </footer>
            </form>
        </>
    );
};

export default VerifyNewEmail;
