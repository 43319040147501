import '../../common/utils.css';
import '../../components/Notice/Notice.css';
import '../../components/TabNav/TabNav.css';
import '../../components/Heading/Heading.css';
import '../../components/Link/Link.css';
import '../../components/Form/Form.css';
import './Login.css';

import { Icon, PacmanLoader } from '@coop/components';
import { BankIdLogoColorIcon } from '@coop/icons';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { accountApi } from '../../common/api/api.accounts';
import type { AccountsDto } from '../../common/api/api.bankid';
import type { ApiException } from '../../common/api/api.types';
import BankId from '../../components/BankId/BankId';
import { useBankid } from '../../components/BankId/BankId.hooks';
import RadioSwitch from '../../components/RadioSwitch/RadioSwitch';
import TabNav from '../../components/TabNav/TabNav';
import { isLoginStateBankidOnly } from '../../core/authState.utils';
import useLoginContext from '../../hooks/useLoginContext';
import useLoginNavigate, { SpaRoutes } from '../../hooks/useLoginNavigate';
import LoginCompany from './foretag/LoginCompany';
import ExternalSigninRememberMe from './kom-ihag/RememberMe';
import LoginEmail from './LoginEmail';

const Login = () => {
    const context = useLoginContext();
    const navigate = useLoginNavigate();
    const location = useLocation();
    const onBankidSuccess = () => {
        getAccountsMutation.mutate();
    };
    const getAccountsMutation = useMutation<AccountsDto[], ApiException, void>({
        mutationFn: accountApi.getBankidUserAccounts,
        onSuccess: (accounts) => {
            // if you already got an email, just login with that one
            if (accounts.length) {
                navigate(SpaRoutes.Login.RememberMe);
            } else {
                navigate(SpaRoutes.CreateAccount.Private.BankId.RegisterEmail);
            }
        },
    });

    const {
        start,
        cancel,
        isStarted,
        isCollecting,
        progress,
        message,
        errorMessage,
        autoStartToken,
        qrCode,
        isCompleted,
    } = useBankid({
        isConfirm: false,
        onSuccess: onBankidSuccess,
    });
    const [tabNavIndex, setTabNavIndex] = useState<number>(0);
    const bankidOnly = isLoginStateBankidOnly(context.authState);
    const isCompanyRoute = location.pathname.startsWith(SpaRoutes.Login.Company);
    const isRememberMe = location.pathname.startsWith(SpaRoutes.Login.RememberMe);
    React.useEffect(() => {
        document.title = 'Coop | Logga in';
    }, []);

    React.useEffect(() => {
        if (bankidOnly) {
            context?.setShowNavigation(true);
        } else {
            context?.setShowNavigation(false);
        }
    }, [context, bankidOnly]);

    const setAccountTypeFromRadio = (e?: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.value) {
            if (e.target.value === 'Private') {
                navigate(SpaRoutes.Login.Start);
            } else {
                navigate(SpaRoutes.Login.Company);
            }
        }
    };
    return (
        <>
            {!isRememberMe && !bankidOnly && (
                <RadioSwitch
                    disabled={isStarted}
                    name="PrivateOrCompany"
                    className="u-marginBmd"
                    labels={['Privat', 'Företag']}
                    values={['Private', 'Company']}
                    id="accountType"
                    defaultValue={isCompanyRoute ? 'Company' : 'Private'}
                    onChange={setAccountTypeFromRadio}
                />
            )}

            <Routes>
                <Route
                    path="/kom-ihag"
                    element={<ExternalSigninRememberMe authState={context.authState} />}
                />
                <Route
                    path="/bankid"
                    element={
                        isLoginStateBankidOnly(context.authState) ? (
                            <>
                                <h1 className="Heading Heading--large u-textCenter">Logga in</h1>
                                <div className="bankidicon-container">
                                    <Icon icon={BankIdLogoColorIcon} />
                                </div>
                                {isCompleted && !getAccountsMutation.isError && (
                                    <div className="u-flex u-marginB u-flexJustifyCenter">
                                        <PacmanLoader />
                                    </div>
                                )}
                                {getAccountsMutation.isError && (
                                    <p className="Notice Notice--red">
                                        Ett fel inträffade vid inloggning. Prova logga in igen.
                                    </p>
                                )}
                                {!isCompleted && (
                                    <BankId
                                        start={start}
                                        cancel={cancel}
                                        isStarted={isStarted}
                                        isCollecting={isCollecting}
                                        disabled={getAccountsMutation.isPending}
                                        progress={progress}
                                        message={message}
                                        errorMessage={errorMessage}
                                        autoStartToken={autoStartToken}
                                        qrCode={qrCode}
                                    />
                                )}
                            </>
                        ) : (
                            <Navigate
                                to={{ pathname: SpaRoutes.Login.Start, search: location.search }}
                            />
                        )
                    }
                />
                <Route path="/foretag" element={<LoginCompany />} />
                <Route
                    path={'/*'}
                    element={
                        <>
                            <h1 className="Heading Heading--large u-textCenter">Logga in privat</h1>
                            <TabNav
                                disabled={isStarted}
                                names={['BankID', 'E-post']}
                                selectedIndex={tabNavIndex}
                                setSelectedIndex={setTabNavIndex}
                            >
                                <div className="u-marginT">
                                    {isCompleted && !getAccountsMutation.isError && (
                                        <div className="u-flex u-marginB u-flexJustifyCenter">
                                            <PacmanLoader />
                                        </div>
                                    )}
                                    {getAccountsMutation.isError && (
                                        <p className="Notice Notice--red">
                                            Ett fel inträffade vid inloggning. Prova logga in igen.
                                        </p>
                                    )}
                                    {!isCompleted && (
                                        <>
                                            <BankId
                                                start={start}
                                                cancel={cancel}
                                                isStarted={isStarted}
                                                isCollecting={isCollecting}
                                                disabled={getAccountsMutation.isPending}
                                                progress={progress}
                                                message={message}
                                                errorMessage={errorMessage}
                                                autoStartToken={autoStartToken}
                                                qrCode={qrCode}
                                            />
                                            <p className="u-textCenter u-marginTsm u-marginBz u-textSmall">
                                                <Link
                                                    className="Link"
                                                    to={{
                                                        pathname: '/skapa-kundkonto',
                                                        search: window.location.search,
                                                    }}
                                                >
                                                    Skapa ett kundkonto
                                                </Link>
                                            </p>
                                        </>
                                    )}
                                </div>
                                <LoginEmail accountType="Private" />
                            </TabNav>
                        </>
                    }
                />
            </Routes>
        </>
    );
};

export default Login;
