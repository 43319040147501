/* eslint-disable react/jsx-props-no-spreading */
import '../../../components/Form/Form.css';
import '../../../components/List/List.css';

import { Button, Checkbox, Icon, PacmanLoader } from '@coop/components';
import { CheckmarkIcon } from '@coop/icons';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { accountApi } from '../../../common/api/api.accounts';
import type { ApiException, AuthenticationStateResult } from '../../../common/api/api.types';
import { getCoopseLoginUrl } from '../../../common/utils';
import { isExternalUserCompany } from '../../../core/authState.utils';
import useLoginContext from '../../../hooks/useLoginContext';
import useLoginNavigate, { SpaRoutes } from '../../../hooks/useLoginNavigate';

interface RememberMeProps {
    rememberMeHeader?: string;
    authState: AuthenticationStateResult;
}

const RememberMe = (props: RememberMeProps) => {
    const { control, handleSubmit } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
        defaultValues: { rememberMe: true },
    });

    const returnUrl = props.authState.loginRequest?.returnUrl || getCoopseLoginUrl();
    const navigate = useLoginNavigate();
    const context = useLoginContext();

    const signInMutation = useMutation<void, ApiException, boolean>({
        mutationFn: (rememberMe: boolean) =>
            accountApi.signinApplicationWithExternalSchema(rememberMe),
        onSuccess: () => {
            window.location.href = decodeURIComponent(returnUrl);
        },
        onError: (error) => {
            if (error.code === 'NO_ACCOUNT') {
                navigate(SpaRoutes.CreateAccount.Private.BankId.RegisterEmail);
            }
            if (error.code === 'RESTART') {
                navigate(SpaRoutes.Login.Start);
            }
        },
    });

    const handleRememberMeFormSubmit = (formData: { rememberMe?: boolean }) => {
        signInMutation.mutate(!!formData.rememberMe);
    };

    const signinWithoutRememberme =
        context?.bankIdOnly &&
        !signInMutation.submittedAt &&
        !signInMutation.isSuccess &&
        !signInMutation.isError &&
        !!props.authState.currentExternalUser;

    React.useEffect(() => {
        context?.setShowNavigation(false);
        // issues here with multiple refetch
        if (signinWithoutRememberme) {
            signInMutation.mutate(false);
        }
    }, [context, signInMutation, signinWithoutRememberme]);

    const handleCancel = () => {
        window.location.href = getCoopseLoginUrl();
    };
    const isLoading = signInMutation.isPending || signInMutation.isSuccess;
    if (context?.bankIdOnly) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">{props.rememberMeHeader}</h1>
                <form className="Form" onSubmit={handleCancel}>
                    <div className="u-marginAutoZero">
                        <div className="u-flex u-flexCenter">
                            <PacmanLoader />
                        </div>
                        <p className="u-textCenter">Loggar in...</p>
                    </div>

                    {signInMutation.isError && (
                        <p className="Notice Notice--red">{signInMutation.error.friendlyMessage}</p>
                    )}
                </form>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Fortsätt vara inloggad?</h1>
            <p className="u-textCenter">Som inloggad på Coop.se kan du t. ex:</p>
            <ul className="List u-textCenter">
                {isExternalUserCompany(props.authState) ? (
                    <>
                        <li className="List-item">
                            <Icon icon={CheckmarkIcon} color="green" className="u-alignMiddle" />
                            &nbsp; <span>handla online</span>
                        </li>
                        <li className="List-item">
                            <Icon icon={CheckmarkIcon} color="green" className="u-alignMiddle" />
                            &nbsp; <span>spara varukorgen</span>
                        </li>
                        <li className="List-item">
                            <Icon icon={CheckmarkIcon} color="green" className="u-alignMiddle" />
                            &nbsp;{' '}
                            <span>
                                betala med faktura via <i>Inköpstjänsten</i>
                            </span>
                        </li>
                    </>
                ) : (
                    <>
                        <li className="List-item">
                            <Icon icon={CheckmarkIcon} color="green" className="u-alignMiddle" />
                            &nbsp; <span>se dina poäng</span>
                        </li>
                        <li className="List-item">
                            <Icon icon={CheckmarkIcon} color="green" className="u-alignMiddle" />
                            &nbsp; <span>spara recept</span>
                        </li>
                        <li className="List-item">
                            <Icon icon={CheckmarkIcon} color="green" className="u-alignMiddle" />
                            &nbsp; <span>handla online</span>
                        </li>
                    </>
                )}
            </ul>
            <>
                {!isExternalUserCompany(props.authState) && (
                    <p className="u-textCenter u-textXSmall">
                        Du kommer att behöva identifiera dig med BankID när du vill göra poängköp,
                        banktjänster osv.
                    </p>
                )}
                <form className="Form" onSubmit={handleSubmit(handleRememberMeFormSubmit)}>
                    <Controller
                        render={({ field }) => (
                            <Checkbox
                                id="remember-me"
                                name="rememberMe"
                                className="u-marginT"
                                label={
                                    isExternalUserCompany(props.authState)
                                        ? 'Ja, håll mig inloggad!'
                                        : 'Ja, håll mig inloggad! Vid behov identifierar jag mig med BankID.'
                                }
                                disabled={isLoading}
                                checked={field.value}
                                onBlur={field.onBlur}
                                onCheckedChange={field.onChange}
                            />
                        )}
                        name="rememberMe"
                        control={control}
                    />

                    <footer className="u-marginTauto">
                        {signInMutation.isError && (
                            <p className="Notice Notice--red">
                                {signInMutation.error.friendlyMessage}
                            </p>
                        )}
                        {isLoading && (
                            <div className="u-flex u-flexJustifyCenter u-marginTmd">
                                <PacmanLoader />
                            </div>
                        )}
                        <Button
                            className="u-marginT"
                            size={48}
                            fullWidth
                            type="submit"
                            disabled={isLoading}
                        >
                            Bekräfta
                        </Button>
                    </footer>
                </form>
            </>
        </>
    );
};

export default RememberMe;
